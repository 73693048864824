<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Counter Widgets -->
		<a-row :gutter="24">
			<a-col :span="24" :lg="12" :xl="6" class="mb-24" v-for="(stat, index) in stats" :key="index">
				<!-- Widget 1 Card -->
				<WidgetCounter
					:title="stat.title"
					:value="stat.value"
					:prefix="stat.prefix"
					:suffix="stat.suffix"
					:icon="stat.icon"
					:status="stat.status"
				></WidgetCounter>
				<!-- / Widget 1 Card -->
			</a-col>
		</a-row>
		<!-- / Counter Widgets -->

		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="24" class="mb-24">
				
				<CardMesas :mesas="mesasData" :callback="createdTicketCallback"></CardMesas>

			</a-col>
		</a-row>

		<!-- Table & Timeline -->
		<a-row :gutter="24" type="flex" align="stretch">
			<!-- Table -->
			<a-col :span="24" :lg="24" class="mb-24 md-24">
				<!-- Projects Table Card -->
				<CardProjectTable
					:data="tableData"
					:columns="tableColumns"
					:callback="createdTicketCallback"
					:mesas="mesasData"
				></CardProjectTable>
				<!-- / Projects Table Card -->
				
			</a-col>
			<!-- / Table -->
		</a-row>

		<!-- Cards -->
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :xl="14" class="mb-24">

			</a-col>
		</a-row>
		<!-- / Cards -->

	</div>
</template>

<script>

	// Bar chart for "Active Users" card.
	import CardBarChart from '../components/Cards/CardBarChart' ;

	// Line chart for "Sales Overview" card.
	import CardLineChart from '../components/Cards/CardLineChart' ;

	// Counter Widgets
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	// "Projects" table component.
	import CardProjectTable from '../components/Cards/CardProjectTable' ;

	// Order History card component.
	import CardOrderHistory from '../components/Cards/CardOrderHistory' ;

	// Information card 1.
	import CardInfo from '../components/Cards/CardInfo' ;

	// Information card 2.
	import CardInfo2 from '../components/Cards/CardInfo2' ;

	import CardMesas from '../components/Cards/CardMesas' ;

	// Counter Widgets stats
	const stats = [
	] ;

	var mesasData = [];
	var ticketsData = [];

	// "Projects" table list of columns and their properties.
	const tableColumns = [
		{
			title: 'Mesa',
			dataIndex: 'company',
			scopedSlots: { customRender: 'company' },
			width: 300,
		},
		// {
		// 	title: 'Miembros',
		// 	dataIndex: 'members',
		// 	scopedSlots: { customRender: 'members' },
		// },
		{
			title: 'Ctd. Miembros',
			dataIndex: 'budget',
			class: 'font-bold text-muted text-sm',
		},
		{
			title: 'Porcentaje',
			scopedSlots: { customRender: 'completion' },
			dataIndex: 'completion',
		},
		{
			title: 'Crear Ticket',
			scopedSlots: { customRender: 'createticket' },
		},
	];

	// "Projects" table list of rows and their properties.
	const tableData = [];

	import Pusher from 'pusher-js';

	export default ({
		components: {
			CardBarChart,
			CardLineChart,
			WidgetCounter,
			CardProjectTable,
			CardOrderHistory,
			CardInfo,
			CardInfo2,
			CardMesas
		},
		// on load, set the data
		mounted() {
			this.stats = stats ;
			this.tableColumns = tableColumns ;
			this.tableData = tableData ;
			this.mesasData = [];
			this.ticketsData = [];
			this.fetchMesasData();

			// Set up Pusher
			const pusher = new Pusher('85232fe772193dd4a52e', {
			cluster: 'us2',
			encrypted: true
			});

			const channel = pusher.subscribe('yk');
			channel.bind('created', (data) => {
				console.log("Received created from pusher", data);
				this.fetchMesasData();
			});

			// fetch data every 15 minutes (just in case, pusher shuold be enough)
			setInterval(() => {
				this.fetchMesasData();
			}, 900000)
			
		},
		methods: {
			createdTicketCallback(){
				this.fetchMesasData();
			},
			calculateCompletionPercentage(ticketsLength, capacidad) {
				if (capacidad === 0) return {
					value: 0,
					label: '0%',
					status: 'normal',
				}; // Avoid division by zero
				let percentage = Math.round((ticketsLength / capacidad) * 100);
				// round percentage to 0 decimal places
				return {
					label: `${percentage}%`, 
					value:percentage,
					status: this.determineStatus(percentage),
				}

			},
			determineStatus(percentage) {
				if (percentage === 100) return 'exception';
				if (percentage > 50) return 'active';
				return 'success';
			},
			async formatMesasData() {
				console.log("formatting mesas data");
				this.tableData = [];
				// this.stats = []
				
				await this.mesasData.forEach(mesa => {
					let mesaType = mesa.localidad.Nombre;
					let icon;
					if (mesaType === 'DIAMANTE') {
						icon = 'images/logos/diamond.png';
					} else if (mesaType === 'ORO') {
						icon = 'images/logos/gold.png';
					} else if (mesaType === 'PLATA') {
						icon = 'images/logos/silver.png';
					} else if (mesaType === 'PLATINO') {
						icon = 'images/logos/silver.png';
					}
					this.tableData.push({
						key: mesa.id,
						documentId: mesa.documentId,
						company: {
							name: `Mesa ${mesaType} - ${mesa.numero}`,
							logo: icon,
						},
						members: Array(mesa.tickets.length).fill("images/face-0.jpg"),
						budget: `${mesa.tickets.length}/${mesa.capacidad}`,
						completion: this.calculateCompletionPercentage(mesa.tickets.length, mesa.capacidad),
						espacio_disponible: mesa.capacidad - mesa.tickets.length,
					})
				})
				this.formatMesasStatus();
				// console.log(this.tableData);
			},
			formatMesasStatus(){
				let mesaStats = [];
				if(this.mesasData){
					this.mesasData.forEach(mesa => {
						let mesa_type = mesa.localidad.Nombre;

						// check if any item inside mesaStats has title == mesa_type
						let mesaStat = mesaStats.find(stat => stat.title === mesa_type);
						if(mesaStat){
							mesaStat.value += mesa.tickets.length;
							mesaStat.total += mesa.capacidad;
							mesaStat.suffix = `${Math.round((mesaStat.value/mesaStat.total)*100)}%`;
						}else{
							mesaStats.push({
								title: mesa_type,
								value: mesa.tickets.length,
								total: mesa.capacidad,
								price: mesa.localidad.precio_publico
							})
						}
				})
				}
			
				console.log("Finished formatting mesas stats data");
				console.log(mesaStats);
				// order mesaStats by price desc
				mesaStats.sort((a, b) => b.price - a.price);
				this.stats = mesaStats;

			},
			fetchMesasData(){
				this.$axios.get('/mesas?populate=*&sort=capacidad:desc&pagination[limit]=10000')
					.then(response => {
						this.mesasData = response.data.data ;
						this.formatMesasData();
					})
					.catch(error => {
						console.log(error) ;
					})
			}
		},
		data() {
			return {

				// Associating table data with its corresponding property.
				tableData,

				// Associating table columns with its corresponding property.
				tableColumns,

				// Counter Widgets Stats
				stats,

				// Mesa data
				mesasData,

				// Tickets data
				ticketsData,
			}
		},
	})

</script>

<style lang="scss">
</style>