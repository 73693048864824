<template>
  <div>
    <a-modal v-model="isModalVisible" title="Registrar Ticket">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="Lugar">
          <a-input :value="selectedTableDescription" disabled />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :xs="24" :sm="12">
            <a-form-item label="Boleta de Pago" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="Boleta de Pago" v-decorator="[
                'Boleta',
                { rules: [{ required: true, message: 'Ingresa la boleta' },
                { validator: checkBoleta, message: 'La boleta ya fue registrada' }
                ] }
              ]" />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-item label="Correlativo de Ticket" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="Correlativo de Ticket" v-decorator="[
                'Correlativo',
                {
                  rules: [{ required: true, message: 'Ingresa correlativo o varios separados por coma' },
                  { pattern: /^\d+(,\d+)*$/, message: 'Ingresa un número o varios separados por coma' },
                  { validator: checkCorrelativo, message: 'El correlativo ya fue registrado' }
                  ]
                }
              ]" 
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="Nombres" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-input placeholder="Nombres" v-decorator="[
            'Nombres',
            { rules: [{ required: true, message: 'Ingresa nombre' },] }
          ]" />
        </a-form-item>
        <a-form-item label="Apellidos" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-input placeholder="Apellidos" v-decorator="[
            'Apellidos',
            { rules: [{ required: true, message: 'Ingresa apellidos' }] }
          ]" />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :xs="24" :sm="12">
        <a-form-item label="Celular" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-input placeholder="Whatsapp/Celular" v-decorator="[
            'Celular',
            {
              rules: [{ required: true, message: 'Ingresa whatsapp/celular' },
              { pattern: /^[0-9]{8}$/, message: 'Ingresa un número de 8 dígitos' }
              ]
            }
          ]" />
        </a-form-item>
      </a-col>
      <a-col :xs="24" :sm="12">

        <a-form-item label="Asociado" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <a-switch v-model:checked="isAfiliado" @change="calcCostoTicket" />
        </a-form-item>
      </a-col>
      </a-row>

        <a-form-item label="Precio total" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
          <span style="color: red; font-size: 2em;">Q. {{ costoTicket }}</span>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handleCancel">Cancelar</a-button>
        <a-button type="primary" @click="handleOk" :loading="loading">OK Crear</a-button>
    </template>
    </a-modal>
    <a-col :xs="23" :sm="23">

    <div class="seating-layout">

      <div class="row general">
        <div style="background-color: grey; width: 100%; padding: 1em; text-align: center; color: white; font-weight: bold; font-size: 1.5em; border-radius: 10px;">
          Escenario
        </div>
      </div>

      <div class="row general">
        <hr style="width: 100%; border: 1px dotted grey; margin: 0;"/>
      </div>
      <div class="row title_seat">
        DIAMANTE
      </div>

       <div class="row">
        <Seat v-for="num in [1,2,3,4,5,6,7,8,9,10,11]" :key="num" :seat="getSeatLocalidad(num, 'DIAMANTE')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>
      
      <div class="row">
        <Seat v-for="num in [12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]" :key="num" :seat="getSeatLocalidad(num, 'DIAMANTE')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>


      <div class="row">
        <Seat v-for="num in [28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45]" :key="num" :seat="getSeatLocalidad(num, 'DIAMANTE')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>


      <div class="row">
        <Seat v-for="num in [46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63]" :key="num" :seat="getSeatLocalidad(num, 'DIAMANTE')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row">
        <Seat v-for="num in [64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81]" :key="num" :seat="getSeatLocalidad(num, 'DIAMANTE')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row general">
        <hr style="width: 100%; border: 1px dotted grey; margin: 0;"/>
      </div>
      <div class="row title_seat">
        PLATINO
      </div>

      <div class="row">
        <Seat v-for="num in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]" :key="num" :seat="getSeatLocalidad(num, 'PLATINO')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row">
        <Seat v-for="num in [19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]" :key="num" :seat="getSeatLocalidad(num, 'PLATINO')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row">
        <Seat v-for="num in [37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54]" :key="num" :seat="getSeatLocalidad(num, 'PLATINO')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row">
        <Seat v-for="num in [55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72]" :key="num" :seat="getSeatLocalidad(num, 'PLATINO')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row">
        <Seat v-for="num in [73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90]" :key="num" :seat="getSeatLocalidad(num, 'PLATINO')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row">
        <Seat v-for="num in [91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108]" :key="num" :seat="getSeatLocalidad(num, 'PLATINO')" :highlight="mesaHighlight==num"
          :handleClick="createTicket" />
      </div>

      <div class="row general">
        <hr style="width: 100%; border: 1px dotted grey; margin: 0;"/>
      </div>
      <div class="row title_seat">
        ORO
      </div>
      <div class="row">
        <Seat v-for="num in [1]" :key="num" :seat="getSeatLocalidad(num, 'ORO')" :handleClick="createTicket"/>
      </div>

      <div class="row general">
        <hr style="width: 100%; border: 1px dotted grey; margin: 0;"/>
      </div>

    </div>
  </a-col>
  <a-col :xs="1" :sm="1">
    <div class="row"  @click="createTicketPlata">
      <span class="vertical-text" :handleClick="createTicket">Plata {{countTicketsPlata}}/{{ countMesasPlata }}</span>
    </div>
    <!-- <div class="row">
        <Seat v-for="num in [400]" :key="num" :seat="getSeat(num)" :handleClick="createTicket" class="vertical-text"/>
      </div> -->
  </a-col>
  </div>
</template>

<script>
import Seat from './Seat.vue';

export default {
  components: {
    Seat,
  },
  props: {
    mesas: {
      type: Array,
      default: () => [],
    },
    callback:
    {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      selectedTableDescription: '',
      selectedTableData : {},
      selectedTableId: null,
      costoTicket: 0,
      isModalVisible: false,
      mesaHighlight: 0,
      isAfiliado: false,
      currentCorrelativos: [],

      // mesas: [], // Stores the data from API
      centralSeats: [
      ],
      loading: false,
    };
  },
  mounted() {
    const urlParams = this.$route.query 

    let mesa = urlParams.mesa || 0;
    console.log("highlighting mesa", mesa)
    console.log(mesa)
    this.mesaHighlight = mesa;

    if(this.mesaHighligh){
      setTimeout(() => {
      this.mesaHighlight = 0;
      // remove mesa from url
      this.$router.push({ query: {} });
    }, 7000);
    }
    
  },
  computed:{
    tickets(){
      return this.mesas.flatMap(mesa => mesa.tickets);
    },
    countMesasPlata () {
      console.log("Counting Mesas Plata")
      let mesa = this.mesas.filter(mesa => mesa.localidad.Nombre === 'PLATA')
      if(mesa.length > 0){
        return mesa[0].capacidad;
      }else{
        return 0;
      }
    },
    countTicketsPlata () {
      let mesas = this.mesas.filter(mesa => mesa.localidad.Nombre === 'PLATA')
      console.log("Counting tickets Mesa Plata", mesas)
      if(mesas.length > 0){
        return mesas[0].tickets.length;
      }else{
        return 0;
      }
    },
  },
  methods: {
    createTicketPlata(){
      const mesa = this.mesas.find((mesa) => mesa.numero == 1 && mesa.localidad.Nombre == 'PLATA');
      this.createTicket(mesa);
    },
    calcCostoTicket(){
      
      let countCorrelativos = this.currentCorrelativos.length;
      
      console.log("Calculating costo", countCorrelativos, this.isAfiliado, this.selectedTableData);

      let costo = this.selectedTableData.localidad.precio_publico;
      if(this.isAfiliado){
        costo = this.selectedTableData.localidad.precio_asociado
      }

      this.costoTicket = costo * countCorrelativos;
    },
    async checkCorrelativo(rule, value, callback) {
      let correlativos = value.split(',');
      console.log(correlativos);

      // validate non duplicated correlativos
      let uniqueCorrelativos = [...new Set(correlativos)];
      if (correlativos.length !== uniqueCorrelativos.length) {
        rule.message = 'No se permiten correlativos duplicados';
        callback(new Error(rule.message));
        return;
      }

      try {
        for (const correlativo of correlativos) {
          const responseExists = await this.$axios.get(`/tickets?filters[Correlativo][$eq]=${correlativo}&filters[mesa][localidad][$eq]=${this.selectedTableData.localidad.id}`);
          if (responseExists.data.data.length > 0) {
            let exists = responseExists.data.data[0];
            console.log(exists);
            rule.message = `El correlativo ${correlativo} ya fue registrado para ${exists.Nombres} con la boleta ${exists.Boleta}`;
            console.log(rule.message);
            callback(new Error(rule.message));
            return; // Stop the loop and exit the function
          }
        }
        // If no correlativo was found, call the callback with no error
        this.currentCorrelativos = correlativos;
        this.calcCostoTicket();
        callback();
      } catch (error) {
        console.error('Error fetching ticket data:', error);
        callback(new Error('Error fetching ticket data'));
      }
    },

    async checkBoleta(rule, value, callback) {
      console.log("Checking correlativo", value)
      // console.log(this.tickets)
      // const exists = this.tickets.find(ticket => ticket.Correlativo === value);
      const responseExists = await this.$axios.get('/tickets?filters[Boleta][$eq]=' + value)
      console.log("Response Exists")
      console.log(responseExists)
      if (responseExists.data.data.length >0) {
        console.log("Exists", responseExists.data.data[0])
        let exists = responseExists.data.data[0]
        console.log(exists)
        rule.message = `El Numero de boleta ya fue registrado para ${exists.Nombres} con el correlativo ${exists.Correlativo}`;
        callback(new Error(rule.message));
      } else {
        callback();
      }
    },
    createTicket(mesa) {
      mesa.tipo = mesa.localidad.Nombre
      console.log("Creating ticket for mesa", mesa)
      this.loading = false;
      this.selectedTableId = mesa.documentId;
      this.isModalVisible = true;
      if(mesa.tipo == "PLATA"){
        this.selectedTableDescription = `${mesa.tipo} #${mesa.numero}`;
      }else {
        this.selectedTableDescription = `Mesa ${mesa.tipo} #${mesa.numero}`;
      }
      
      // this.selectedTableDescription = `Mesa ${mesa.tipo} #${mesa.numero}`;
      this.selectedTableData = mesa;
      this.isAfiliado = false;
      this.currentCorrelativos = [];
      this.costoTicket = 0;
      // clear fields on modal open
      this.form.resetFields();
    },
    getSeatLocalidad(num, localidad){
      const mesa = this.mesas.find((mesa) => mesa.numero == num && mesa.localidad.Nombre == localidad);
      if(mesa){
        return {
            id: mesa.numero,
            description: mesa.Descripcion,
            capacity: mesa.capacidad,
            occupied: mesa.tickets.length,
            tipo: mesa.localidad.Nombre,
            mesa: mesa
          };
      } else {
        // Return a placeholder if no data is found for the seat number
        return {
          id: num,
          description: `Error ${num}`,
          capacity: 0,
          occupied: 0,
          tipo: 'N/A',
          mesa: {}
        };
      }
      
    },
    getSeat(num) {
      if (num >= 200 && num < 300) {
        return {
          id: "--",
          description: "Directivos",
          capacity: "Directivos",
          occupied: "",
          tipo: "Directivos",
          mesa: null
        };
      }
      if (num >= 300) {
        let real_num = num - 299;
        const mesa = this.mesas.find((mesa) => mesa.numero == real_num && mesa.tipo == 'Oro');
        if (mesa) {
          console.log("Mesa Oro:", mesa, num, real_num)
          return {
            id: mesa.numero,
            description: mesa.Descripcion,
            capacity: mesa.capacidad,
            occupied: mesa.tickets.length,
            tipo: mesa.tipo,
            mesa: mesa
          };
        }
      }
      const mesa = this.mesas.find((mesa) => mesa.numero === num && mesa.tipo == 'Diamante');
      if (mesa) {
        return {
          id: mesa.numero,
          description: mesa.Descripcion,
          capacity: mesa.capacidad,
          occupied: mesa.tickets.length,
          tipo: mesa.tipo,
          mesa: mesa
        };
      } else {
        // Return a placeholder if no data is found for the seat number
        return {
          id: num,
          description: `Mesa ${num}`,
          capacity: 0,
          occupied: 0,
          tipo: 'N/A',
          mesa: null
        };
      }
    },
    handleCancel() {
      this.isModalVisible = false;
      this.loading = false; 
    },
    async handleOk(e) {
      e.preventDefault();
      this.loading = true; 
      let current_user = JSON.parse(localStorage.getItem('user'));

      try {
        // Get mesa details from API
        let mesa = await this.$axios.get(`/mesas/${this.selectedTableId}?populate=*`);
        console.log("Getting MESA before save", mesa.data.data);

        let mesa_capacidad = mesa.data.data.capacidad;
        let mesa_tickets = mesa.data.data.tickets.length;
        console.log("Capacidad de Mesa", mesa_capacidad);
        console.log("Tickets en Mesa", mesa_tickets);

        // Calculate the number of tickets being created
        let creating_tickets = this.form.getFieldValue('Correlativo').split(',').length;
        console.log("Creating Tickets", creating_tickets);

        // Check if the mesa has enough capacity
        if (creating_tickets + mesa_tickets > mesa_capacidad) {
          this.$error({
            title: 'Error',
            content: 'La mesa seleccionada no tiene espacio suficiente para la cantidad de tickets requeridos',
          });
          this.loading = false; // Reset loading state
          return;
        }

        // Validate form fields
        this.form.validateFields(async (err, values) => {
          if (!err) {
            let correlativos = values.Correlativo.split(',').map(correlativo => correlativo.trim());
            let allSuccess = true;
            let countCorrelativos = correlativos.length;
            let precioPorTicket = this.costoTicket/countCorrelativos

            for (const correlativo of correlativos) {
              let formData = {
                data: {
                  mesa: this.selectedTableId,
                  Boleta: values.Boleta,
                  Nombres: values.Nombres,
                  Apellidos: values.Apellidos,
                  Correlativo: correlativo,
                  celular_whatsapp: values.Celular,
                  Fecha: new Date(),
                  afiliado: this.isAfiliado,
                  precio: precioPorTicket,
                  notas: `Ticket creado por ${current_user.username} con costo Q. ${precioPorTicket} y asociado: ${this.isAfiliado}, cantidad de tickets ${creating_tickets}`,
                  username: current_user.username,
                }
              };

              try {
                this.$gtag.event('create-ticket', { data: formData , user: current_user});
                let response = await this.$axios.post('/tickets', formData);
                console.log(response);
              } catch (err) {
                console.error('Error posting ticket:', err);
                this.$gtag.event('create-ticket-error', { data: formData });
                this.$error({
                  title: 'Error',
                  content: 'Ha ocurrido un error al registrar el ticket, revise que todos los campos esten ingresados y el correlativo no se repita',
                });
                allSuccess = false;
                break; // Stop the iteration if an error occurs
              }
            }

            if (allSuccess) {
              this.isModalVisible = false;
              this.selectedTableId = null;
              this.$success({
                title: 'Ticket registrado',
                content: 'Todos los tickets han sido registrados exitosamente',
              });
              this.callback();
              this.$gtag.event('create-ticket-success', { data: formData });
            }
          } else {
            console.error('Validation Error:', err);
          }
          this.loading = false; // Reset loading state
        });
      } catch (error) {
        console.error('Error fetching mesa data:', error);
        this.$error({
          title: 'Error',
          content: 'Ha ocurrido un error al obtener los datos de la mesa',
        });
        this.loading = false
      }
    },
    handleCloseModal() {
      this.isModalVisible = false;
      this.selectedTableId = null;
    },
  },
};
</script>

<style scoped>
.row.general {
  width: 80%;
}

.seating-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  margin: 10px;
}

.center-section {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.vertical-text {
  writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 47px;
    height: 700px;
    background-color: gray;
    color: white;
    padding: 2px;
    padding-bottom: 100px;
    border-radius: 10px;
    text-align: center;
    width: 500px;
    margin-left: -33px;
}
.title_seat {
    font-size: 2em;
    font-weight: 800;
    margin: 0;
    padding: 0;
}
</style>